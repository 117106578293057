
import { defineComponent } from 'vue';
//import {RRSettings, composableRRSettings} from '@/composables/rrsettingsmodule';
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonRow, IonCol, IonButton, IonText } from '@ionic/vue';
import {peopleOutline, peopleSharp, pawOutline, pawSharp} from 'ionicons/icons';

interface Card {
    type: string,
    location?: string,
    hero?: string
}

export default defineComponent({
    components: {
        IonButtons,
        IonContent,
        IonHeader,
        IonMenuButton,
        IonPage,
        IonTitle,
        IonToolbar,
        IonRow,
        IonCol,
        IonButton,
        IonText
    },
    // setup() {
    //     const {settings, loadSettings, setGameSettings, getSettings, openError} = composableRRSettings();
    //     return {
    //         settings,
    //         loadSettings,
    //         setGameSettings,
    //         getSettings,
    //         openError
    //     }
    // },
    data() {
        return {
            gameSettings: {
                players: 0,
                outcasts: 0,
                cards: new Array<Card>(),
                location: 0,
                started: false
            },
            foundOutcasts: 0
        }
    },
    watch: {
        '$route.name' (to, from) {
            // KUZYT - JUST DO THIS...
            if (to === 'game.process') {
                this.initProcess();
            }
        }
    },
    mounted() {
        this.initProcess();
    },
    methods: {
        initProcess() {
            this.gameSettings = this.$store.getters['settings'].game;
            this.foundOutcasts = 0;
        },

        finishGame() {
            this.$store.dispatch('setGameSettings', {});
            this.$router.push({ name: 'home' });
        }
    }
})
