
import { defineComponent } from 'vue';
//import {RRSettings, composableRRSettings} from '@/composables/rrsettingsmodule';
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonImg, IonThumbnail, IonButton, IonRow, IonCol } from '@ionic/vue';

export default defineComponent({
    components: {
        IonButtons,
        IonContent,
        IonHeader,
        IonMenuButton,
        IonPage,
        IonTitle,
        IonToolbar,
        IonImg,
        IonThumbnail,
        IonButton,
        IonRow,
        IonCol
    },
    // setup() {
    //     return composableRRSettings();
    // },
    computed: {
        logoImg() {
            return require('@/assets/logo_small.png');
        },

        settings() {
            return this.$store.getters['settings'];
        }
    }
})
