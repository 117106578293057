import { createStore } from 'vuex'

import { informationCircle } from 'ionicons/icons';
import { Storage } from '@ionic/storage';
const baseStore = new Storage();
import axios from "axios";
import { toastController } from '@ionic/vue';

export interface RRSettings {
  date?: string
  world?: any
  custom?: any
  game?: any
}

export default createStore({
  state: {
    settings: <RRSettings>{},
    wasInited: false,
    isLoading: false,
    lastError: ''
  },
  getters: {
    settings (state) {
      return state.settings;
    },
    wasInited (state) {
      return state.wasInited;
    },
    // setting: (state) => (key: any) => {
    //   return key.split('.').reduce((o,i) => o ? o[i] : null, state);
    // },
  },
  mutations: {
    setSettings (state, payload: RRSettings) {
      state.settings = payload;
    },

    setLastError (state, payload) {
      state.lastError = payload;
    },

    setInited (state) {
      state.wasInited = true;
    }
  },
  actions: {

    /**
     * Вызов ошибки
     * @param commit
     * @param error
     */
    async openError ({ commit }, error: any) {
      if (error instanceof Object) {
        const newError = error.response.data.error
            ? error.response.data.error
            : (error.response.data.message ? error.response.data.message : error.response.data);
        let longErrors = '';
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).forEach(errorKey => longErrors += (longErrors ? '\r\n' : '') + error.response.data.errors[errorKey]);
        }

        error = longErrors ? longErrors : newError;
      }

      commit('setLastError', error);

      const toast = await toastController
          .create({
            header: 'Ошибка!',
            message: error,
            icon: informationCircle,
            position: 'top',
            duration: 3000,
            color: 'warning'
          })
      await toast.present();
    },

    /**
     * Загрузка настроек
     * @param dispatch
     * @param commit
     * @param getters
     */
    loadSettings({ dispatch, commit, getters }) {
      axios.get(process.env.VUE_APP_API_URL + 'list')
          .then((response) => {
            if (response.data && Object.keys(response.data).length) {
              dispatch('setRrSettings', Object.assign({}, getters.settings, { date: Date.now() }, response.data));
              if (!getters.wasInited) {
                commit('setInited');
              }
            }
          })
          .catch((reason) => {
            dispatch('openError', reason);
          });
    },

    /**
     * Записать настройки в переменную и в
     * @param commit
     * @param getters
     * @param locRrSettings
     */
    async setRrSettings({ commit, getters }, locRrSettings: RRSettings) {
      commit('setSettings', locRrSettings);
      const strRrSettings = JSON.stringify(getters.settings);
      //console.log("Setting new - " + strRrSettings);
      return await baseStore.set('rrSettings', strRrSettings);
    },

    /**
     * Сохраняем игровые настройки
     * @param dispatch
     * @param getters
     * @param game
     */
    setGameSettings ({ dispatch, getters }, game: any) {
      // На всякий случай проверяем, что было инициализировано
      if (!getters.wasInited || !getters.settings) {
        dispatch('openError', "Ошибка сохранения настроек. Попробуйте перезапустить.");
        return;
      }

      const settings = Object.assign({}, getters.settings);
      settings.game = game;
      dispatch('setRrSettings', settings);
    },

    /**
     * Запросить настройки из хранилища. Должно вызываться один раз при инициализации.
     */
    async getRrSettings() {
      const strRrSettings = await baseStore.get('rrSettings');
      return strRrSettings ? JSON.parse(strRrSettings) : strRrSettings;
    },

    /**
     * Инициализировать базовое хранилище. Должно вызываться один раз при инициализации.
     */
    async prepareBaseStore() {
      const created = await baseStore.create();
      //console.log('Created!');
      //console.log(created);
      return created;
    },

    /**
     * Инициализация
     * @param dispatch
     * @param commit
     * @param getters
     */
    init ({ dispatch, commit, getters }) {
      dispatch('prepareBaseStore').then(() => {
        dispatch('getRrSettings').then((val) => {
            commit('setSettings', val);
            //console.log('Needed val:');
            //console.log(val);
            if (getters.settings) {
              commit('setInited');
            } else {
              dispatch('loadSettings');
            }
          })
        });
    }
  },
  modules: {
  }
})
