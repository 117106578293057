import {createRouter, createWebHistory} from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';
import HomePage from "@/views/HomePage.vue";
import SettingsPage from "@/views/SettingsPage.vue";
import GameOptions from "@/views/GameOptions.vue";
import GameCards from "@/views/GameCards.vue";
import GameProcess from "@/views/GameProcess.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'home',
        component: HomePage
    },
    {
        path: '/settings',
        component: SettingsPage
    },
    {
        path: '/game/options',
        name: 'game.options',
        component: GameOptions
    },
    {
        path: '/game/cards',
        name: 'game.cards',
        component: GameCards
    },
    {
        path: '/game/process',
        name: 'game.process',
        component: GameProcess
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
