
import { defineComponent } from 'vue';
//import {RRSettings, composableRRSettings} from '@/composables/rrsettingsmodule';
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonRow, IonCol, IonIcon, IonItem, IonList, IonRange, IonButton, IonLabel } from '@ionic/vue';
import {peopleOutline, peopleSharp, pawOutline, pawSharp} from 'ionicons/icons';

export default defineComponent({
    components: {
        IonButtons,
        IonContent,
        IonHeader,
        IonMenuButton,
        IonPage,
        IonTitle,
        IonToolbar,
        IonRow,
        IonCol,
        IonIcon,
        IonItem,
        IonList,
        IonRange,
        IonButton,
        IonLabel
    },
    // setup() {
    //     const {settings, loadSettings, setGameSettings, openError} = composableRRSettings();
    //     return {
    //         settings,
    //         loadSettings,
    //         setGameSettings,
    //         openError
    //     }
    // },
    data() {
        return {
            peopleIcon: { ios: peopleSharp, md: peopleOutline },
            outcastIcon: { ios: pawSharp, md: pawOutline },
            minPlayers: 3,
            maxPlayers: 50,
            players: 3,
            minOutcasts: 1,
            outcasts: 1,
            isOutcastRandom: false,
            outcastRandomRange: { lower: 1, upper: 3 }
        }
    },
    watch: {
        players() {
            if (this.outcasts > this.players) {
                this.outcasts = this.players;
            }
            if (this.outcastRandomRange.upper > this.players) {
                this.outcastRandomRange.upper = this.players;
            }
            if (this.outcastRandomRange.lower > this.players) {
                this.outcastRandomRange.lower = this.players;
            }
            //console.log(this.outcastRandomRange);
        }
    },
    methods: {
        lessPlayers() {
            this.players--;
            if (this.players < this.minPlayers) {
                this.players = this.minPlayers;
            }
        },
        morePlayers() {
            this.players++;
            if (this.players > this.maxPlayers) {
                this.players = this.maxPlayers;
            }
        },
        lessOutcasts() {
            if (this.isOutcastRandom) {
                this.isOutcastRandom = false;
            }
            this.outcasts--;
            if (this.outcasts < this.minOutcasts) {
                this.outcasts = this.minOutcasts;
            }
        },
        moreOutcasts() {
            if (this.isOutcastRandom) {
                this.isOutcastRandom = false;
            }
            this.outcasts++;
            if (this.outcasts > this.players) {
                this.outcasts = this.players;
            }
        },
        randomOutcasts() {
            if (this.isOutcastRandom) {
                return;
            }
            if (this.outcastRandomRange.upper > this.players) {
                this.outcastRandomRange.upper = this.players;
            }
            if (this.outcastRandomRange.lower > this.players) {
                this.outcastRandomRange.lower = this.players;
            }
            this.isOutcastRandom = true;
        },
        startGameCards() {
            const gameSettings = {
                players: this.players,
                outcasts: this.isOutcastRandom
                    ? Math.floor(Math.random() * (this.outcastRandomRange.upper - this.outcastRandomRange.lower + 1)) + this.outcastRandomRange.lower
                    : this.outcasts,
                started: false
            }
            //console.log(gameSettings);
            this.$store.dispatch('setGameSettings', gameSettings);
            this.$router.push({ name: 'game.cards' });
        }
    }
})
