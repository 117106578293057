
import {
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonRouterOutlet,
    IonSplitPane,
    IonProgressBar
} from '@ionic/vue';
import {defineComponent, ref} from 'vue';
import {useRoute} from 'vue-router';
import {homeOutline, homeSharp, settingsOutline, settingsSharp} from 'ionicons/icons';

export default defineComponent({
    name: 'App',
    components: {
        IonApp,
        IonContent,
        IonIcon,
        IonItem,
        IonLabel,
        IonList,
        IonListHeader,
        IonMenu,
        IonMenuToggle,
        IonNote,
        IonRouterOutlet,
        IonSplitPane,
        IonProgressBar
    },
    setup() {
        const selectedIndex = ref(0);
        const appPages = [
            {
                title: 'Главная',
                url: '/home',
                iosIcon: homeOutline,
                mdIcon: homeSharp
            },
            {
                title: 'Настройки',
                url: '/settings',
                iosIcon: settingsOutline,
                mdIcon: settingsSharp
            }
        ];

        const path = window.location.pathname.split('folder/')[1];
        if (path !== undefined) {
            selectedIndex.value = appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
        }

        const route = useRoute();

        return {
            selectedIndex,
            appPages,
            homeOutline, homeSharp, settingsOutline, settingsSharp,
            isSelected: (url: string) => url === route.path ? 'selected' : ''
        }
    },
    created() {
        this.$store.dispatch('init');
    },
    computed: {
        wasInited() {
            return this.$store.getters['wasInited'];
        }
    }
});
