
import { defineComponent } from 'vue';
//import {RRSettings, composableRRSettings} from '@/composables/rrsettingsmodule';
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonButton, IonRow, IonCol } from '@ionic/vue';

export default defineComponent({
    components: {
        IonButtons,
        IonContent,
        IonHeader,
        IonMenuButton,
        IonPage,
        IonTitle,
        IonToolbar,
        IonButton,
        IonRow,
        IonCol
    },
    // setup() {
    //     const {settings, loadSettings, openError} = composableRRSettings();
    //     return {
    //         settings,
    //         loadSettings,
    //         openError
    //     }
    // },
    methods: {
        showDate(settings: any) {
            if (settings && settings.date) {
                let date = new Date(settings.date);
                return date.toLocaleDateString("ru-RU") + ' ' + date.toLocaleTimeString("ru-RU");
            }
            return null;
        },

        loadSettings() {
            this.$store.dispatch('loadSettings');
        }
    },
    computed: {
        settings() {
            return this.$store.getters['settings'];
        }
    }
})
