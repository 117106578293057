
/* eslint no-use-before-define: 0 */  // --> OFF
import { defineComponent } from 'vue';
//import {RRSettings, composableRRSettings} from '@/composables/rrsettingsmodule';
//import { Swiper, SwiperSlide } from 'swiper/vue';
//import { /*Keyboard, Pagination, Scrollbar, Zoom,*/ EffectCards/*, EffectFlip, Controller*/ } from 'swiper';
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar,/* IonRow, IonCol,*/ IonButton,/* IonicSlides, IonCard, IonCardHeader, IonCardContent, IonCardTitle, IonCardSubtitle*/ } from '@ionic/vue';
//import {peopleOutline, peopleSharp, pawOutline, pawSharp} from 'ionicons/icons';
//import 'swiper/css';
// import 'swiper/css/keyboard';
// import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';
// import 'swiper/css/zoom';
//import "swiper/css/effect-cards";
//import "swiper/css/effect-flip";
//import '@ionic/vue/css/ionic-swiper.css';
import {RRSettings} from "@/store";
//import CardSwipe from '@/components/CardSwipe.vue';
//import CardSwipeItem from '@/components/CardSwipeItem.vue';

import VueSwing from '@/components/VueSwing.vue';

interface Card {
    type: string,
    location?: string,
    hero?: string,
    number: number
}

export default defineComponent({
    components: {
        IonButtons,
        IonContent,
        IonHeader,
        IonMenuButton,
        IonPage,
        IonTitle,
        IonToolbar,
        //IonRow,
        //IonCol,
        IonButton,
        //Swiper,
        //SwiperSlide,
        //IonCard, IonCardHeader/*, IonCardContent*/, IonCardTitle, IonCardSubtitle,
        //CardSwipe, CardSwipeItem
        VueSwing
    },
    setup() {
        //const {settings, loadSettings, setGameSettings, getSettings, openError} = composableRRSettings();
        // const controlledSwiper = ref(null);
        //
        // const setControlledSwiper = (swiper: any) => {
        //     controlledSwiper.value = swiper;
        // }

        return {
            // settings,
            // loadSettings,
            // setGameSettings,
            // getSettings,
            // openError,
            // Controller,
            // controlledSwiper,
            // setControlledSwiper,
            //modules: [/*Keyboard, Pagination, Scrollbar, Zoom,*/ EffectCards/*, Controller*/, IonicSlides],
            //modulesFlip: [/*Keyboard, Pagination, Scrollbar, Zoom,*/ EffectFlip, Controller, IonicSlides],
        }
    },
    data() {
        return {
            gameSettings: {
                players: 0,
                outcasts: 0,
                cards: new Array<Card>(),
                location: 0,
                started: false
            },
            cards: new Array<Card>(),
            openedCards: new Array<number>(),
            isOpen: -1,
            countDragged: 0,
            //innerSwiper: null,
            backImgNumber: 1,
            isDraggingCard: false
        }
    },
    // beforeRouteEnter (to, from, next) {
    //     next(vm => {
    //         // KUZYT - NOT WORKING CALLBACK, IT IS VUE BUG
    //         this.initCards();
    //         console.log('enter game cards');
    //     });
    // },
    // beforeRouteUpdate (to, from, next) {
    //     // обрабатываем изменение параметров маршрута...
    //     // не забываем вызвать next()
    //     // KUZYT - NOT WORKING CAUSE NO ROUTE PARAMS CHANGES
    //     this.initCards();
    //     console.log('update game cards');
    //     next();
    // },
    watch: {
        '$route.name' (to, from) {
            // KUZYT - JUST DO THIS...
            if (to === 'game.cards') {
                this.initCards();
            }
        }
    },
    mounted() {
        this.initCards();
    },
    methods: {
        initCards() {
            this.openedCards = new Array<number>();
            this.isOpen = -1;
            this.countDragged = 0;
            this.backImgNumber = Math.floor(Math.random() * 4) + 1;

            //console.log('Init Cards from GameCards');
            this.gameSettings = this.settings.game;
            if (!this.gameSettings) {
                this.$router.push({ name: 'game.options' });
                return;
            }
            if (this.gameSettings.started) {
                this.$router.push({ name: 'game.process' });
                return;
            }
            //console.log(this.settings);
            if (!this.gameSettings.cards) {
                let sets = this.settings.custom;

                let setNumber, set;
                do {
                    setNumber = Math.floor(Math.random() * sets.length);
                    set = sets[setNumber];
                } while (!set.locations.length);

                let locationNumber = Math.floor(Math.random() * set.locations.length);
                let location = set.locations[locationNumber];

                //console.log(location);

                let cards = new Array<Card>();
                let outcastNumbers = new Array<number>();
                let heroNumber = 0;
                for (let i = 1; i <= this.gameSettings.outcasts; i++) {
                    let currentOutcastNumber = Math.floor(Math.random() * this.gameSettings.players) + 1;
                    while (outcastNumbers.indexOf(currentOutcastNumber) !== -1) {
                        currentOutcastNumber = Math.floor(Math.random() * this.gameSettings.players) + 1;
                    }
                    outcastNumbers.push(currentOutcastNumber);
                }
                //console.log(outcastNumbers);
                for (let i = 1; i <= this.gameSettings.players; i++) {
                    if (outcastNumbers.find((number) => number === i)) {
                        cards.push({ type: 'outcast', number: this.gameSettings.players - i + 1 });
                    } else {
                        let card = { type: 'hero', location: location.title, hero: '', number: this.gameSettings.players - i + 1  }
                        if (location.heroes.length > heroNumber) {
                            card.hero = location.heroes[heroNumber].name;
                            heroNumber++;
                        }
                        cards.push(card);
                    }
                }
                this.gameSettings.cards = cards;
                this.$store.dispatch('setGameSettings', Object.assign(this.gameSettings, { cards: this.gameSettings.cards }));
            }/* else {
                this.setGameSettings(Object.assign(this.gameSettings, { cards: null }));
            }*/
            this.cards = this.gameSettings.cards;
        },

        openEnd() {
            this.$store.dispatch('setGameSettings', Object.assign(this.gameSettings, { started: true }));
            this.cards = new Array<Card>();
            this.$router.push({ name: 'game.process' });
        },

        turnCard(card: Card) {
            if (this.isDraggingCard) {
                //console.log('no turn, dragging');
                return;
            }
            //console.log('turn card');
            this.isOpen = this.isOpen === card.number ? -1 : card.number;
            setTimeout(() => this.openedCards.push(card.number), 500);
        },

        throwout() {
            //console.log('throwout');
            this.isDraggingCard = true;
        },

        throwoutend() {
            //console.log('throwoutend');
            setTimeout(() => this.isDraggingCard = false, 300);
            this.countDragged++;
            this.isOpen = -1;
        },

        throwin() {
            //console.log('throwin');
            this.isDraggingCard = true;
        },

        throwinend() {
            //console.log('throwinend');
            setTimeout(() => this.isDraggingCard = false, 300);
        }

        // afterInit(swiper: any) {
        //     console.log('SWIPER');
        //     console.log(swiper);
        //     this.innerSwiper = swiper;
        // }
    },
    computed: {
        settings(): RRSettings {
            return this.$store.getters['settings'];
        },

        frontImg(): string {
            return require('@/assets/front_small.png');
        },

        backImg(): string { // KUZYT - : string важно для computed, если используется переменная из другого раздела (для синтаксиса TS)
            /* eslint-disable */
            return require(`@/assets/back_${this.backImgNumber}_small.png`);
        },

        backOutcastImg(): string {
            return require('@/assets/back_outcast_small.png');
        },

        swingConfig():object {
            return {
                minThrowOutDistance: Math.max(window.innerWidth, window.innerHeight),
                maxThrowOutDistance: Math.max(window.innerWidth, window.innerHeight),
                /**
                 * Invoked in the event of dragmove.
                 * Returns a value between 0 and 1 indicating the completeness of the throw out condition.
                 * Ration of the absolute distance from the original card position and element width.
                 *
                 * @param {number} xOffset Distance from the dragStart.
                 * @param {number} yOffset Distance from the dragStart.
                 * @param {HTMLElement} element Element.
                 * @returns {number}
                 */
                throwOutConfidence: (xOffset, yOffset, element) => {
                    const xConfidence = Math.min(Math.abs(xOffset) * 2 / element.offsetWidth, 1);
                    const yConfidence = Math.min(Math.abs(yOffset) * 2 / element.offsetHeight, 1);
                    return Math.max(xConfidence, yConfidence);
                }
            }
        }
    }
})
